import {RequestError} from "../../Model/RequestError";
import axios from "axios";
import {CmsResourceSearch} from "../../Model/CmsResource/CmsResourceSearch";
import {CmsResource} from "../../Model/CmsResource/CmsResource";
import {Reseller} from "../../Types/Reseller";
import {joinUrl} from "../../Utilities/UriUtilities";

/**
 * Function will attempt to find resources in the CMS that matches the given search criteria.
 *
 * @param cmsDomain Base endpoint for the LCS Content Management System.
 * @param cmsProjectName The name of the project that this instance of the sales portal is associated with.
 * @param reseller The name of the Reseller we are trying to get content for.
 * @param search Search options that will determine what is returned by the CMS.
 */
export const searchCmsResources = (
    cmsDomain: string,
    cmsProjectName: string,
    reseller: Reseller,
    search: CmsResourceSearch
): Promise<RequestError | CmsResource[]> => {
    const formattedReseller = reseller.charAt(0).toUpperCase() + reseller.slice(1)
    const endpoint = joinUrl(cmsDomain, `/public/${formattedReseller}/${cmsProjectName}/asset/search`)
    const withQueryParams = `${endpoint}?page=${search.pageNumber}&sort=priority&direction=DESC` +
        (search.pageSize !== undefined ? `&pageSize=${search.pageSize}` : "") +
        (search.tags !== undefined ? `&tags=${search.tags.map(encodeURIComponent).join(",")}` : "")
    const headers = {"Accept": "application/json"}

    // TODO Sort out error handling
    return axios.get<CmsResource[]>(withQueryParams, {headers: headers})
        .then((response) => response.data)
    // .catch((error) => ({
    //     message: error?.response?.data["message"] ?? "We could not fetch any resource at this time. Please refresh the page and try again."
    // }))
}
