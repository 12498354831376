import * as React from "react"
import {useNavigate, useSearchParams} from "react-router-dom";
import {ApplicationContext} from "../../ApplicationContext";
import {CmsContent} from "../../Model/CmsContent/CmsContent";
import {getPromoQueryParam, SALES_PORTAL_ROUTES} from "../../Routes/SalesPortalRoutes";
import {joinUrl} from "../../Utilities/UriUtilities";
import {isRequestError} from "../../Model/RequestError";
import {exchangeHybrisAuthorizationCode} from "../../Api/Hybris/HybrisAuthenticationApi";
import HeaderFooterWrapper from "../../Components/Navigation/HeaderFooterWrapper";
import LoadingBackdrop from "../../Components/LoadingBackdrop/LoadingBackdrop";
import {checkCustomerLink} from "../../Api/Hybris/UserManagementApi";
import {saveLocalStorageItem} from "../../Utilities/LocalStorageUtilities";
import {setPromoCodeToLocalStorage} from "../../Components/Superdrug/Card/PromoCodeComponent";

const AUTHORIZATION_CODE_KEY: string = "authorization_code"

/**
 * Function will render the Hybris callback page which will extract the authorization code and pass it
 * to the parent frame.
 */
export const HybrisCallbackPage = (): null => {

    const applicationContext = React.useContext(ApplicationContext)
    const [searchParams] = useSearchParams()

    React.useEffect(() => {
        const maybeAuthorizationCode = searchParams.get("code")
        const targetOrigin = new URL(applicationContext.salesPortalWebappPublicUrl).origin

        setTimeout(() => { // React technique to guarantee DOM has been rendered.
            window.parent.postMessage(`${AUTHORIZATION_CODE_KEY}=${maybeAuthorizationCode}`, targetOrigin)
        }, 0)
    }, [])

    return null

}

interface SuperdrugLoginPageProps {

    /**
     * Array of all content currently stored in the CMS that has been made available to the Sales Portal.
     */
    readonly cmsContent: CmsContent[]

}

/**
 * Function will render the Superdrug-specific login page.
 */
const SuperdrugLoginPage = (props: SuperdrugLoginPageProps): JSX.Element => {

    const applicationContext = React.useContext(ApplicationContext)
    const navigate = useNavigate()
    const [searchParams] = useSearchParams();

    const [loading, setLoading] = React.useState<boolean>(true)

    const planCode = searchParams.get("plan")
    const promoCode = getPromoQueryParam(useSearchParams())

    setPromoCodeToLocalStorage()

    const checkLinkStatusAndHandleRedirect = (accessToken: string) => {
        checkCustomerLink(applicationContext.salesPortalApiDomain, accessToken).then((maybeCustomerLink) => {
            if (isRequestError(maybeCustomerLink)) {
                // Couldn't determine the Hybris -> DX link, redirect to error page.
                navigate(SALES_PORTAL_ROUTES.InternalError(applicationContext.urlContext))
            } else if (maybeCustomerLink.orderExists && maybeCustomerLink.orderActive) {
                window.location.replace(applicationContext.selfcareWebappPublicUrl!!)
            } else if (maybeCustomerLink.orderExists && !maybeCustomerLink.orderActive) {
                window.location.replace(joinUrl(applicationContext.selfcareWebappPublicUrl!!, "/activate"))
            } else if (planCode) { // Order does not exist in ALLinONE
                // Navigate to user update page for web-sale journey.
                navigate(`${SALES_PORTAL_ROUTES.SimOnly.UserUpdate(applicationContext.urlContext)}?plan=${planCode}${promoCode ? `&promo=${promoCode}` : ""}`)
            } else { // Order does not exist in ALLinONE
                // Navigate to user update page for sim-in-hand journey.
                navigate(SALES_PORTAL_ROUTES.SimInHand.UserUpdate(applicationContext.urlContext))
            }
        })
    }

    const handleAuthorizationFlow = (authorizationCode: string) => {
        const hybrisConfig = applicationContext.appConfig.authenticationConfiguration.hybrisAuthenticationConfiguration

        if (hybrisConfig) {
            setLoading(true)
            const formattedPublicUrl = applicationContext.salesPortalWebappPublicUrl.endsWith("/") ?
                applicationContext.salesPortalWebappPublicUrl.substring(0, applicationContext.salesPortalWebappPublicUrl.length - 1) : applicationContext.salesPortalWebappPublicUrl

            exchangeHybrisAuthorizationCode(applicationContext.salesPortalApiDomain, {
                authorizationCode: authorizationCode,
                redirectUrl: SALES_PORTAL_ROUTES.HybrisCallback(formattedPublicUrl)
            }).then((maybeToken) => {
                if (isRequestError(maybeToken)) {
                    // Couldn't get access token, redirect to error page.
                    navigate(SALES_PORTAL_ROUTES.InternalError(applicationContext.urlContext))
                } else {
                    checkLinkStatusAndHandleRedirect(maybeToken.accessToken)
                }
            })
        }
    }

    React.useEffect(() => {
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            saveLocalStorageItem("access_token", "access_token")
            saveLocalStorageItem("refresh_token", "refresh_token")
            saveLocalStorageItem("expires_at", 1000000000)

            if (planCode) {
                navigate(`${SALES_PORTAL_ROUTES.SimOnly.UserUpdate(applicationContext.urlContext)}?plan=${planCode}${promoCode ? `&promo=${promoCode}` : ""}`)
            } else {
                navigate(SALES_PORTAL_ROUTES.SimInHand.UserUpdate(applicationContext.urlContext))
            }
        } else {
            window.addEventListener("message", (event) => {
                // Validate the origin of the message.
                if (event.origin === new URL(applicationContext.salesPortalWebappPublicUrl).origin) {
                    // Validate the message data is of type authorisation code.
                    if (event.data && typeof event.data === "string" && event.data.startsWith(AUTHORIZATION_CODE_KEY)) {
                        const authorizationCode = event.data.replace(AUTHORIZATION_CODE_KEY, "").replace("=", "")
                        handleAuthorizationFlow(authorizationCode)
                    }
                } else {
                    // Ignore the message
                    console.warn('Unauthorized postMessage from ' + event.origin);
                }
            })
        }
    }, [])

    /**
     * Function will render an IFrame to the Hybris authorization server.
     */
    const HybrisLoginIframe = (): JSX.Element | null => {
        // Hacky function to clear the loading gif without re-rendering the component.
        const clearLoadingOnFrameLoad = () => {
            const loadingBackdropWrapper = document.getElementById("loading-backdrop-wrapper")
            if (loadingBackdropWrapper && loadingBackdropWrapper.parentNode) {
                loadingBackdropWrapper.parentNode.removeChild(loadingBackdropWrapper)
            }
        }

        const hybrisConfig = applicationContext.appConfig.authenticationConfiguration.hybrisAuthenticationConfiguration

        if (hybrisConfig) {
            const formattedBaseEndpoint = hybrisConfig.iframeBaseUrl.endsWith("/") ?
                hybrisConfig.iframeBaseUrl.substring(0, hybrisConfig.iframeBaseUrl.length - 1) : hybrisConfig.iframeBaseUrl
            const redirectUrl = joinUrl(applicationContext.salesPortalWebappPublicUrl, "/hybris-callback")
            const hybrisIframeUri = formattedBaseEndpoint +
                `?clientId=${hybrisConfig.clientId}` +
                `&redirectToPage=${redirectUrl}` +
                '&loyaltyEnrolmentUrl=/welcome-loyalty-enrolment-sso-mobile'

            return (
                <div className="hybris-wrapper">
                    <div id="iframe-wrapper" className="hybris-iframe-wrapper">
                        <iframe
                            onLoad={clearLoadingOnFrameLoad}
                            title="Login"
                            width="100%"
                            height="100%"
                            style={{border: 0, minHeight: 900}}
                            src={hybrisIframeUri}
                            sandbox="allow-same-origin allow-scripts allow-popups allow-forms allow-top-navigation allow-storage-access-by-user-activation"
                        />
                    </div>
                </div>
            )
        } else {
            return null
        }
    }

    return (
        <HeaderFooterWrapper cmsContent={props.cmsContent} variant="secondary">
            <HybrisLoginIframe/>

            <div id="loading-backdrop-wrapper">
                <LoadingBackdrop isLoading={loading}/>
            </div>
        </HeaderFooterWrapper>
    )
}

export default SuperdrugLoginPage
