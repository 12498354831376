import * as React from "react"
import HeaderFooterWrapper from "../../Components/Navigation/HeaderFooterWrapper";
import {optHtmlContent, renderDynamicContent} from "../../Utilities/CmsUtilities";
import SuperdrugOrderSummaryCard from "../../Components/Superdrug/Card/SuperdrugOrderSummaryCard";
import {ApplicationContext} from "../../ApplicationContext";
import {useLocation, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {SimOnlyPlan} from "../../Model/Sales/SimOnlyPlan";
import dropin, {cardPaymentMethodPayload, Dropin} from "braintree-web-drop-in";
import {CmsContent} from "../../Model/CmsContent/CmsContent";
import {getPromoQueryParam, SALES_PORTAL_ROUTES} from "../../Routes/SalesPortalRoutes";
import {createBraintreeClientToken} from "../../Api/Sales/BraintreeApi";
import {isRequestError} from "../../Model/RequestError";
import CmsForm from "../../Components/CmsForm/CmsForm";
import {CmsFormFieldErrorMap, CmsFormSchema} from "../../Model/CmsContent/CmsFormSchema";
import {fetchSimOnlyPlanByCode, fetchSimOnlyPlanByPlanCodeAndPromoCode} from "../../Api/Sales/SimOnlyPlanApi";
import {createOrder} from "../../Api/Sales/OrderApi";
import {UserDetails} from "../../Model/User/UserDetails";
import dayjs from "dayjs";
import {fetchHybrisUser} from "../../Api/Hybris/UserManagementApi";
import LoadingBackdrop from "../../Components/LoadingBackdrop/LoadingBackdrop";
import {OrderConfirmation} from "../../Model/Sales/OrderConfirmation";
import {saveLocalStorageItem} from "../../Utilities/LocalStorageUtilities";
import {toggleInputError} from "../../Utilities/CmsFormUtilities";
import {isSimDetails, SimDetails} from "../../Model/Sim/SimDetails";
import {readSessionItem} from "../../Utilities/SessionUtilities";
import CmsStaticHtmlContent from "../../Components/CmsStaticHtmlContent/CmsStaticHtmlContent";
import {InlinedState} from "../../Types/InlinedState";
import {PlanPrice} from "../../Model/Sales/PlanPrice";
import {fetchPriceForPlanWithPromo} from "../../Api/Sales/PricesApi";
import {HostedFieldsEvent} from "braintree-web/hosted-fields";
import {truncateString} from "../../Utilities/StringUtilities";

interface PaymentCaptureFormProps {

    /**
     * Array of all content currently stored in the CMS that has been made available to the Sales Portal.
     */
    readonly cmsContent: CmsContent[]

    /**
     * State-stored instance of the Braintree dropin.
     */
    readonly braintreeInstance: InlinedState<Dropin | undefined>

    /**
     * Callback function to be called when submitting the form.
     */
    readonly submitOrder: (formData: FormData, errorMap: CmsFormFieldErrorMap[]) => void

}

/**
 * Function will render the Braintree payment capture form.
 */
const PaymentCaptureForm = (props: PaymentCaptureFormProps): JSX.Element | null => {

    const applicationContext = React.useContext(ApplicationContext)
    const navigate = useNavigate()

    React.useEffect(() => {
        if (!props.braintreeInstance.value) {
            createBraintreeClientToken(applicationContext.salesPortalApiDomain).then((maybeClientToken) => {
                if (isRequestError(maybeClientToken)) {
                    navigate(SALES_PORTAL_ROUTES.InternalError(applicationContext.urlContext))
                } else {
                    const braintreeConfig = {
                        authorization: maybeClientToken.clientToken,
                        container: "#braintree-drop-in-div",
                        threeDSecure: true
                    }

                    dropin.create(braintreeConfig, (error, instance) => {
                        if (error || !instance) {
                            navigate(SALES_PORTAL_ROUTES.InternalError(applicationContext.urlContext))
                        } else {
                            props.braintreeInstance.setValue(instance)
                        }
                        document.getElementById("braintree-payment-capture-placeholder")?.classList.add("d-none")
                        document.getElementById("braintree-drop-in-div")?.classList.remove("d-none")
                    })
                }
            })
        }
    }, [])

    const optPaymentCaptureCard = optHtmlContent(props.cmsContent, "sp-card-payment-capture-sim-in-hand")
    const paymentCaptureForm = props.cmsContent.find((content) => content.reference === "form-payment-capture")

    if (optPaymentCaptureCard && paymentCaptureForm) {
        const form = paymentCaptureForm.jsonContent as CmsFormSchema

        return renderDynamicContent(optPaymentCaptureCard, [
            {key: "ACTIVATE_URL", value: SALES_PORTAL_ROUTES.SimInHand.activate(applicationContext.urlContext)},
            {
                key: "PAYMENT_CAPTURE",
                value: <CmsForm
                    id="braintree-payment-capture"
                    form={form}
                    submit={(formData) => props.submitOrder(formData, form.errorMap)}
                    widget={{
                        content: <React.Fragment>
                            <div
                                id="braintree-drop-in-div"
                                className="d-none"
                                style={{marginTop: "-1rem", marginBottom: "-1rem"}}
                            />

                            <div id="braintree-payment-capture-placeholder">
                                <CmsStaticHtmlContent
                                    cmsContent={props.cmsContent}
                                    reference="sp-card-braintree-placeholder"
                                />
                            </div>
                        </React.Fragment>,
                        position: "ABOVE"
                    }}
                />
            }
        ])
    } else {
        return null
    }
}

interface SuperdrugSimInHandPlanPurchasePageProps {

    /**
     * Array of all content currently stored in the CMS that has been made available to the Sales Portal.
     */
    readonly cmsContent: CmsContent[]

}

/**
 * Function will render the Superdrug-specific sim-in-hand purchase page which will sign up a user with a physical SIM.
 */
const SuperdrugSimInHandPlanPurchasePage = (props: SuperdrugSimInHandPlanPurchasePageProps): JSX.Element | null => {

    const applicationContext = React.useContext(ApplicationContext)
    const navigate = useNavigate()
    const location = useLocation()
    const portCapturePageState = location.state
    const {planCode} = useParams<keyof { readonly planCode: string }>()
    const promoCode = getPromoQueryParam(useSearchParams())

    const [componentData, setComponentData] = React.useState<{
        readonly selectedPlan: SimOnlyPlan
        readonly selectedPlanPriceWithPromo: PlanPrice | null
        readonly userDetails: UserDetails
        readonly simDetails: SimDetails
        readonly paidInStorePlan: SimOnlyPlan | null
        readonly paidInStorePriceWithPromo: PlanPrice | null
    }>()

    const [loading, setLoading] = React.useState<boolean>(false)
    const [braintreeDropin, setBraintreeDropin] = React.useState<Dropin>()
    const [braintreeFormValid, setBraintreeFormValid] = React.useState<boolean>(false)

    /**
     * Function will submit a new order request effectively signing them up to the MVNO.
     */
    const submitOrder = (formData: FormData, errorMap: CmsFormFieldErrorMap[]) => {
        if (braintreeDropin && componentData) {
            const termsAndConditions = formData.get("termsAndConditions")
            const termsAndConditionsValid = termsAndConditions !== null && termsAndConditions === "on"
            toggleInputError(errorMap, "braintree-payment-capture", "termsAndConditions", termsAndConditionsValid ? undefined : "You must agree to our terms and conditions")
            toggleInputError(errorMap, "braintree-payment-capture", "termsAndConditions", braintreeFormValid ? undefined : "Some payment card details are missing or invalid")
            if (termsAndConditionsValid && braintreeFormValid) {
                let price: number;
                if (componentData.paidInStorePlan) {
                    if (componentData.paidInStorePlan.packageCode === componentData.selectedPlan.packageCode) {
                        price = 0.0;
                    } else {
                        if (componentData.selectedPlanPriceWithPromo) {
                            price = componentData.selectedPlanPriceWithPromo.priceToPay - (componentData.paidInStorePlan?.monthlyPrice || 0.0);
                            if (price < 0){
                                price = 0.0
                            }
                        } else {
                            price = componentData.selectedPlan.monthlyPrice - componentData.paidInStorePlan.monthlyPrice;
                        }
                    }
                } else {
                    if (componentData.selectedPlanPriceWithPromo) {
                        price = componentData.selectedPlanPriceWithPromo.priceToPay;
                    } else {
                        price = componentData.selectedPlan.monthlyPrice;
                    }
                }
                let userDetails = componentData!!.userDetails!;
                braintreeDropin.requestPaymentMethod({
                    threeDSecure: {
                        amount: price.toString(),
                        email: userDetails.email,
                        billingAddress : {
                            givenName: truncateString(userDetails.forename, 50),
                            surname: truncateString(userDetails.surname, 50),
                            streetAddress: truncateString(`${userDetails.address?.streetNumber} ${userDetails.address?.building} ${userDetails.address?.line1}`, 50),
                            extendedAddress: truncateString(`${userDetails.address?.line2}`, 50),
                            locality: userDetails.address?.town,
                            postalCode: userDetails.address?.postCode
                        },
                        challengeRequested: true,
                    }
                }, (error, payload) => {
                    if (error) {
                        navigate(SALES_PORTAL_ROUTES.InternalError(applicationContext.urlContext))
                    } else {
                        submitOrderCreationRequest(
                          payload.nonce,
                          payload.deviceData || null,
                          (payload as cardPaymentMethodPayload)?.threeDSecureInfo?.threeDSecureAuthenticationId || '',
                          componentData.userDetails,
                          componentData.selectedPlan,
                          componentData.simDetails,
                        )
                    }
                })
            }
        } else {
            navigate(SALES_PORTAL_ROUTES.InternalError(applicationContext.urlContext))
        }
    }

    const submitOrderCreationRequest = (
        paymentNonce: string | null,
        paymentDeviceData: string | null,
        paymentThreeDSecureAuthenticationId: string | null,
        userDetails: UserDetails,
        selectedPlan: SimOnlyPlan,
        simDetails: SimDetails,
    ) => {
        setLoading(true)

        let optPromoCode;
        const defaultStaffPromo = userDetails?.hybris?.cardNumber?.startsWith("2660") ?
            applicationContext.appConfig.signupConfiguration.staffPromo : null

        if (defaultStaffPromo === null && componentData?.selectedPlanPriceWithPromo !== null){
            optPromoCode = componentData?.selectedPlanPriceWithPromo.promoCode ?? null
        }else {
            optPromoCode = defaultStaffPromo
        }

        const splitPostcodeArray = userDetails.address.postCode!!
            .replace(/\s/g, "")
            .match(/^([A-Za-z]{1,2}\d{1,2}[A-Za-z]?)\s*(\d[A-Za-z]{2})$/);
        splitPostcodeArray!!.shift()

        createOrder(applicationContext.reseller, applicationContext.salesPortalApiDomain, {
            chosenPackageCode: selectedPlan.packageCode,
            packageBoltonCodes: selectedPlan.planBolton ? [selectedPlan.planBolton.code] : [],
            customer: {
                title: null,
                firstName: userDetails.forename,
                lastName: userDetails.surname,
                dateOfBirth: dayjs(userDetails.dateOfBirth).format("YYYY-MM-DD"),
                email: userDetails.email,
                externalCustomerRef: userDetails.hybris!!.customerId
            },
            homeAddress: {
                houseNumber: userDetails.address.streetNumber,
                houseName: userDetails.address.building,
                line1: userDetails.address.line1,
                line2: userDetails.address.line2,
                line3: null,
                city: userDetails.address.town,
                county: null,
                postcode1: splitPostcodeArray!![0],
                postcode2: splitPostcodeArray!![1]
            },
            directDebitDetails: null,
            braintreePaymentNonce: paymentNonce,
            braintreePaymentThreeDSecureAuthenticationId: paymentThreeDSecureAuthenticationId,
            braintreePaymentDeviceData: paymentDeviceData,
            marketingPreferences: {
                emailMarketing: true,
                smsMarketing: true,
                postMarketing: true,
                phoneMarketing: true,
                automatedVoiceMarketing: true,
                onlineMarketing: true,
                inAppMarketing: true,
                profiledMarketing: true,
                thirdPartyMarketing: true
            },
            iccid: simDetails.iccid,
            promoCode: optPromoCode,
            loyaltyCard: userDetails.hybris!!.cardNumber,
            portInNumber: portCapturePageState?.msisdn ?? null,
            switchingCode: portCapturePageState?.switchingCode ?? null
        }).then((maybeOrderSummary) => {
            if (isRequestError(maybeOrderSummary)) {
                navigate(SALES_PORTAL_ROUTES.InternalError(applicationContext.urlContext))
            } else {
                const orderConfirmation: OrderConfirmation = {
                    packageCode: selectedPlan.packageCode,
                    email: userDetails.email,
                    houseNumber: userDetails.address.streetNumber,
                    building: userDetails.address.building,
                    line1: userDetails.address.line1,
                    line2: userDetails.address.line2,
                    city: userDetails.address.town,
                    postcode: userDetails.address.postCode
                }
                saveLocalStorageItem("order-confirmation", orderConfirmation)
                navigate(SALES_PORTAL_ROUTES.SimInHand.Confirmation(applicationContext.urlContext))
            }
            setLoading(false)
        })
    }

    /**
     * Function will fetch all the information needed for this component before rendering it.
     */
    const setupComponent = async () => {
        const maybeToken = await applicationContext.accessToken()
        if (!maybeToken) {
            navigate(SALES_PORTAL_ROUTES.Login(applicationContext.urlContext))
            return
        }

        const optSimDetails = readSessionItem("sim-details", isSimDetails)
        if (!optSimDetails) {
            // Sim details have been lost from session data, get the user to enter them them again.
            navigate(SALES_PORTAL_ROUTES.SimInHand.activate(applicationContext.urlContext))
            return
        }

        const maybeUser = await fetchHybrisUser(applicationContext.salesPortalApiDomain, maybeToken)
        if (isRequestError(maybeUser)) {
            navigate(SALES_PORTAL_ROUTES.InternalError(applicationContext.urlContext))
            return
        }



        let inStorePlan: SimOnlyPlan | null = null
        const isLeadGen = optSimDetails?.leadGenerator ?? false

        const optPromoCode = maybeUser.hybris?.cardNumber?.startsWith("2660") ?
            applicationContext.appConfig.signupConfiguration.staffPromo : null
        let planPriceWithPromo: PlanPrice | null
        let inStorePriceWithPromo: PlanPrice | null
        const maybePlan = await fetchSimOnlyPlanByPlanCodeAndPromoCode(
            applicationContext.reseller, applicationContext.salesPortalApiDomain, planCode!!, optPromoCode ?? promoCode)
        if (isRequestError(maybePlan)) {
            navigate(SALES_PORTAL_ROUTES.InternalError(applicationContext.urlContext))
            return
        }
        if (!isLeadGen) {
            if (applicationContext.appConfig.signupConfiguration.defaultPlan === planCode) {
                if(optPromoCode){
                    inStorePlan = {...maybePlan, monthlyPrice: maybePlan.monthlyPrice - maybePlan.promoDiscount!!.value}
                } else {
                    inStorePlan = maybePlan
                }
            } else {
                if (optPromoCode) {
                    const maybeInStorePlan = await fetchSimOnlyPlanByPlanCodeAndPromoCode(
                        applicationContext.reseller, applicationContext.salesPortalApiDomain,
                        applicationContext.appConfig.signupConfiguration.defaultPlan!!, optPromoCode)
                    if (isRequestError(maybeInStorePlan)) {
                        navigate(SALES_PORTAL_ROUTES.InternalError(applicationContext.urlContext))
                        return
                    }
                    inStorePlan = {...maybeInStorePlan,
                        monthlyPrice: maybeInStorePlan.monthlyPrice - maybeInStorePlan.promoDiscount!!.value}
                } else {
                    const maybeInStorePlan = await fetchSimOnlyPlanByCode(
                        applicationContext.reseller, applicationContext.salesPortalApiDomain, applicationContext.appConfig.signupConfiguration.defaultPlan!!)
                    if (isRequestError(maybeInStorePlan)) {
                        navigate(SALES_PORTAL_ROUTES.InternalError(applicationContext.urlContext))
                        return
                    }
                    inStorePlan = maybeInStorePlan
                }
            }
        }
        if (optPromoCode) { // Customer hasn't selected a plan yet for SIH journey.
            const maybePlanPrice = await fetchPriceForPlanWithPromo(
                applicationContext.reseller, applicationContext.salesPortalApiDomain, planCode!!, optPromoCode)
            if (isRequestError(maybePlanPrice)) {
                navigate(SALES_PORTAL_ROUTES.InternalError(applicationContext.urlContext))
                return
            }
            planPriceWithPromo = maybePlanPrice

            if (planPriceWithPromo && applicationContext.appConfig.signupConfiguration.defaultPlan === planCode) {
                inStorePriceWithPromo = planPriceWithPromo
            } else {
                const maybeInStorePlanPrice = await fetchPriceForPlanWithPromo(
                    applicationContext.reseller, applicationContext.salesPortalApiDomain, applicationContext.appConfig.signupConfiguration.defaultPlan!!, optPromoCode)
                if (isRequestError(maybeInStorePlanPrice)) {
                    navigate(SALES_PORTAL_ROUTES.InternalError(applicationContext.urlContext))
                    return
                }
                inStorePriceWithPromo = maybeInStorePlanPrice
            }
        } else {
            planPriceWithPromo = null
            inStorePriceWithPromo = null
            if(promoCode) {
                const maybePlanPrice = await fetchPriceForPlanWithPromo(
                    applicationContext.reseller, applicationContext.salesPortalApiDomain, planCode!!, promoCode)
                if (isRequestError(maybePlanPrice)) {
                    navigate(SALES_PORTAL_ROUTES.InternalError(applicationContext.urlContext))
                    return
                }
                planPriceWithPromo = maybePlanPrice
                if(!isLeadGen && inStorePlan) {
                    inStorePriceWithPromo = {...planPriceWithPromo,
                        priceToPay: planPriceWithPromo.priceToPay - inStorePlan.monthlyPrice}
                }else {
                    inStorePriceWithPromo = {...planPriceWithPromo,
                        priceToPay: planPriceWithPromo.priceToPay}
                }
            }
        }

        setComponentData({
            selectedPlan: maybePlan,
            selectedPlanPriceWithPromo: planPriceWithPromo,
            userDetails: maybeUser,
            simDetails: optSimDetails,
            paidInStorePlan: inStorePlan,
            paidInStorePriceWithPromo: inStorePriceWithPromo
        })
    }

    React.useEffect(() => {
        setupComponent()
    }, [planCode])

    const optOrderSummaryPage = optHtmlContent(props.cmsContent, "sp-page-sim-in-hand-signup")

    const setSelectedPlanPriceWithPromo = (planPrice: PlanPrice | null) => {
        setComponentData(prevState => ({
            ...prevState!,
            selectedPlanPriceWithPromo: planPrice
        }))
    }

    const setSelectedPlan = (simOnlyPlan: SimOnlyPlan) => {
        setComponentData(prevState => ({
            ...prevState!,
            selectedPlan: simOnlyPlan
        }))
    }

    if (!componentData) {
        return null // Prevent page flicker while we are getting everything for the page.
    } else if (optOrderSummaryPage) {
        return (
            <HeaderFooterWrapper cmsContent={props.cmsContent} variant="tertiary">
                {renderDynamicContent(optOrderSummaryPage, [
                    {
                        key: "ORDER_SUMMARY",
                        value: <SuperdrugOrderSummaryCard
                            cmsContent={props.cmsContent}
                            selectedPlan={componentData.selectedPlan}
                            selectedPlanPriceWithPromo={componentData.selectedPlanPriceWithPromo}
                            paidInStorePlan={componentData.paidInStorePlan}
                            paidInStorePriceWithPromo={componentData.paidInStorePriceWithPromo}
                            allowEdit={true}
                            simInHand={true}
                            setSelectedPlanPriceWithPromo={setSelectedPlanPriceWithPromo}
                            setSelectedPlan={setSelectedPlan}
                            componentData={componentData}
                        />
                    },
                    {
                        key: "PAYMENT_CAPTURE",
                        value: <PaymentCaptureForm
                            cmsContent={props.cmsContent}
                            braintreeInstance={{value: braintreeDropin, setValue: ((instance: Dropin | undefined) => {
                                if (instance) {
                                    instance.on("card:validityChange", (event: HostedFieldsEvent) => {
                                        setBraintreeFormValid(event.fields.number.isValid
                                          && event.fields.cvv.isValid
                                          && event.fields.expirationDate.isValid
                                          && event.fields.postalCode.isValid)
                                    })
                                }
                                setBraintreeDropin(instance)
                            })}}
                            submitOrder={submitOrder}
                        />
                    }
                ])}

                <LoadingBackdrop isLoading={loading}/>
            </HeaderFooterWrapper>
        )
    } else {
        return null
    }

}

export default SuperdrugSimInHandPlanPurchasePage