import * as React from "react";
import DOMPurify from "dompurify";
import parse, {DOMNode} from 'html-react-parser';
import {CmsContent} from "../Model/CmsContent/CmsContent";
import {KeyValue} from "../Types/KeyValue";

/**
 * Utility function will take a templated content string and attempt to fill it using the given array of template tags
 * following these rules:
 *
 *   1. When the value of the key/value pair is of type string, a simple find and replace will be performed.
 *      i. If you have a templated string like "Hello, {{forename}}", the expected output would be "Hello, Liam".
 *   2. When the value of the key/value pair is of type React.ReactNode[], the entire node will be replaced with the
 *      array of React.ReactNodes. If the array is empty, we will instead return an empty React.Fragment to hide the
 *      template tag from the DOM.
 *   3. When the value of the key/value pair is of type React.ReactNode, the entire node will be replaced with the
 *      React.ReactNode.
 *
 * @param templateContent Mustache-templated content string that will be processed.
 * @param templateTags Array of key/value pairs that will be used when processing the template.
 */
export const renderDynamicContent = (
    templateContent: string,
    templateTags: KeyValue<string | React.ReactNode | React.ReactNode[]>[] = []
): JSX.Element | null => {
    const findAndReplaceGenericNode = (node: DOMNode, dataField: string): JSX.Element | DOMNode => {
        const optTemplateTag = templateTags.find((templateTag) => dataField.includes(templateTag.key))

        if (optTemplateTag) {
            if (Array.isArray(optTemplateTag.value)) {
                // Only render something if the template tag has a value. Otherwise, hide the template tag from the DOM.
                return (
                    <React.Fragment>
                        {optTemplateTag.value.length > 0 && optTemplateTag.value}
                    </React.Fragment>
                )
            } else {
                return <React.Fragment>{optTemplateTag.value}</React.Fragment>
            }
        } else {
            return node
        }
    }

    // Sanitize the template to prevent XSS attacks.
    let partiallyProcessedContent: string = DOMPurify.sanitize(templateContent, {
        WHOLE_DOCUMENT: false,
        ADD_TAGS: ['head', 'iframe'],
        ADD_ATTR: ['target', 'allowfullscreen', 'frameborder'] // Allow target="_blank" for links
    })

    // Perform basic string replacement where appropriate. {{forename}} -> Ross or @@forename@@ -> Ross
    templateTags.forEach((tag) => {
        if (typeof tag.value === "string") {
            partiallyProcessedContent = partiallyProcessedContent
                .replaceAll(`{{${tag.key}}}`, tag.value)
                .replaceAll(`@@${tag.key}@@`, tag.value)
        }
    })

    let processedContent: string | JSX.Element | JSX.Element[]
    if (templateTags.length > 0) {
        processedContent = parse(partiallyProcessedContent, {
            // Replacer function will traverse through the given DOM, looking for template variables. If a template
            // variable is found, we will replace it with something in the given templateTags array.
            replace: (node: DOMNode): JSX.Element | DOMNode | null => {
                const isGenericNode = "data" in node && typeof node["data"] === "string"

                if (isGenericNode) {
                    return findAndReplaceGenericNode(node, (node as any)["data"] as string)
                } else {
                    return node
                }
            }
        })
    } else {
        processedContent = parse(partiallyProcessedContent)
    }

    return (
        <React.Fragment>
            {processedContent}
        </React.Fragment>
    )
}

/**
 * Utility function will attempt to find content with the given reference and return it's HTML representation. If the
 * content cannot be found, null will be returned.
 *
 * @param content Array of content that may contain the reference.
 * @param reference Reference of the content we want to return.
 */
export const optHtmlContent = (content: CmsContent[], reference: String): string | null =>
    content.find((content) => content.reference === reference)?.htmlContent ?? null
