import {isRequestError, RequestError} from "../../Model/RequestError";
import {isPlanPrice, PlanPrice} from "../../Model/Sales/PlanPrice";
import {Reseller} from "../../Types/Reseller";
import {joinUrl} from "../../Utilities/UriUtilities";
import {acceptHeader, executeWithErrorResponseHandling} from "../ApiUtilities";
import axios from "axios";
import {readSessionItem, saveSessionItem} from "../../Utilities/SessionUtilities";
import {isArrayOf} from "../../Utilities/TypeGuardUtilities";

/**
 * Function will fetch the price of a plan with no promotion applied.
 *
 * @param reseller Name of the reseller that is currently configured in this instance.
 * @param apiDomain Base endpoint for the sales portal API.
 * @param planCode Plan code that we are going to get the price for.
 */
export const fetchPriceForPlan = async (
    reseller: Reseller,
    apiDomain: string,
    planCode: string
): Promise<RequestError | PlanPrice> => {
    const maybeSessionData = readSessionItem<PlanPrice[] | null>(
        "plan-prices", (a: any): a is PlanPrice[] => isArrayOf(a, isPlanPrice))
    const maybePlanPrice = maybeSessionData?.find((planPrice) => planPrice.planCode === planCode)

    if (maybePlanPrice) {
        return maybePlanPrice
    }

    const endpoint = joinUrl(apiDomain, `/${reseller}/prices/${encodeURIComponent(planCode)}/view`)
    const headers = acceptHeader()

    const response = await executeWithErrorResponseHandling<PlanPrice>(() =>
        axios.get(endpoint, {headers: headers}))

    if (isRequestError(response)) {
        return response
    } else {
        let newSessionDataArray: PlanPrice[]
        if (maybeSessionData !== null) {
            newSessionDataArray = maybeSessionData
        } else {
            newSessionDataArray = []
        }
        newSessionDataArray.push(response.data)
        saveSessionItem("plan-prices", newSessionDataArray)

        return response.data
    }
}

/**
 * Function will fetch the price of a plan with an applied promotion.
 *
 * @param reseller Name of the reseller that is currently configured in this instance.
 * @param apiDomain Base endpoint for the sales portal API.
 * @param planCode Plan code that we are going to get the price for.
 * @param promoCode Promotion code that will be applied to the returned price.
 */
export const fetchPriceForPlanWithPromo = async (
    reseller: Reseller,
    apiDomain: string,
    planCode: string,
    promoCode: string
): Promise<RequestError | PlanPrice> => {

    const endpoint = joinUrl(apiDomain, `/${reseller}/prices/${encodeURIComponent(planCode)}/view/${encodeURIComponent(promoCode)}/promo`)
    const headers = acceptHeader()

    const response = await executeWithErrorResponseHandling<PlanPrice>(() =>
        axios.get(endpoint, {headers: headers}))

    if (isRequestError(response)) {
        return response
    } else {
        return response.data
    }
}
