import * as React from "react"
import ReactDOM from "react-dom"
import TagManager, {TagManagerArgs} from 'react-gtm-module';
import {isReseller} from "./Types/Reseller";
import SalesPortal from "./SalesPortal";
import InternalErrorPage from "./Pages/InternalErrorPage/InternalErrorPage";

const optContext = window._env_ && window._env_.REACT_APP_CONTEXT ?
    window._env_.REACT_APP_CONTEXT : process.env.REACT_APP_CONTEXT
const optResellerName = window._env_ && window._env_.REACT_APP_RESELLER_NAME ?
    window._env_.REACT_APP_RESELLER_NAME : process.env.REACT_APP_RESELLER_NAME
const optSalesPortalApiDomain = window._env_ && window._env_.REACT_APP_SALES_PORTAL_API_DOMAIN ?
    window._env_.REACT_APP_SALES_PORTAL_API_DOMAIN : process.env.REACT_APP_SALES_PORTAL_API_DOMAIN
const optCmsApiDomain = window._env_ && window._env_.REACT_APP_CMS_API_DOMAIN ?
    window._env_.REACT_APP_CMS_API_DOMAIN : process.env.REACT_APP_CMS_API_DOMAIN
const optCmsProjectName = window._env_ && window._env_.REACT_APP_CMS_PROJECT_NAME ?
    window._env_.REACT_APP_CMS_PROJECT_NAME : process.env.REACT_APP_CMS_PROJECT_NAME
const optSalesPortalWebappPublicUrl = window._env_ && window._env_.REACT_APP_SALES_PORTAL_PUBLIC_URL ?
    window._env_.REACT_APP_SALES_PORTAL_PUBLIC_URL : process.env.REACT_APP_SALES_PORTAL_PUBLIC_URL
const optSelfcareWebappPublicUrl = window._env_ && window._env_.REACT_APP_SELFCARE_PUBLIC_URL ?
    window._env_.REACT_APP_SELFCARE_PUBLIC_URL : process.env.REACT_APP_SELFCARE_PUBLIC_URL
const optSelfcareApiEndpoint = window._env_ && window._env_.REACT_APP_SELFCARE_API_ENDPOINT ?
  window._env_.REACT_APP_SELFCARE_API_ENDPOINT : process.env.REACT_APP_SELFCARE_API_ENDPOINT
const optGtmId = window._env_ && window._env_.REACT_APP_GTM_ID ?
    window._env_.REACT_APP_GTM_ID : process.env.REACT_APP_GTM_ID
const optGtmEvents = window._env_ && window._env_.REACT_APP_GTM_EVENTS ?
    window._env_.REACT_APP_GTM_EVENTS : process.env.REACT_APP_GTM_EVENTS
const optGtmAuth = window._env_ && window._env_.REACT_APP_GTM_AUTH ?
    window._env_.REACT_APP_GTM_AUTH : process.env.REACT_APP_GTM_AUTH
const optGtmPreview = window._env_ && window._env_.REACT_APP_GTM_PREVIEW ?
    window._env_.REACT_APP_GTM_PREVIEW : process.env.REACT_APP_GTM_PREVIEW

if (optContext && isReseller(optResellerName) && optSalesPortalApiDomain && optCmsApiDomain && optSalesPortalWebappPublicUrl) {
    if (optGtmId) {
        let tagManagerArgs: TagManagerArgs = {gtmId: optGtmId};
        if (optGtmEvents) tagManagerArgs = Object.assign(tagManagerArgs, {events: JSON.parse(optGtmEvents)})
        if (optGtmAuth) tagManagerArgs = Object.assign(tagManagerArgs, {auth: optGtmAuth})
        if (optGtmPreview) tagManagerArgs = Object.assign(tagManagerArgs, {preview: optGtmPreview})
        TagManager.initialize(tagManagerArgs)
    }

    ReactDOM.render(
        <SalesPortal
            context={optContext}
            reseller={optResellerName}
            salesPortalApiDomain={optSalesPortalApiDomain}
            cmsApiDomain={optCmsApiDomain}
            cmsProjectName={optCmsProjectName ?? "sales-portal"}
            salesPortalWebappPublicUrl={optSalesPortalWebappPublicUrl}
            selfcareWebappPublicUrl={optSelfcareWebappPublicUrl}
            selfcareApiEndpoint={optSelfcareApiEndpoint}
        />,
        document.getElementById("root")
    )
} else {
    ReactDOM.render(
        <InternalErrorPage cmsContent={[]}/>,
        document.getElementById("root")
    )
}
