import axios from "axios"
import {RequestError} from "../../Model/RequestError";
import {CmsContentSearch} from "../../Model/CmsContent/CmsContentSearch";
import {CmsContent} from "../../Model/CmsContent/CmsContent";
import {Reseller} from "../../Types/Reseller";
import {joinUrl} from "../../Utilities/UriUtilities";

/**
 * Function will attempt to find content in the CMS that matches the given search criteria.
 *
 * @param cmsDomain Base endpoint fo the LCS Content Management System.
 * @param cmsProjectName The name of the project that this instance of the sales portal is associated with.
 * @param reseller The name of the Reseller we are trying to get content for.
 * @param search Search options that will determine what is returned by the CMS.
 */
export const searchCmsContent = async (
    cmsDomain: string,
    cmsProjectName: string,
    reseller: Reseller,
    search: CmsContentSearch
): Promise<RequestError | CmsContent[]> => {
    const formattedReseller = reseller.charAt(0).toUpperCase() + reseller.slice(1)
    const endpoint = joinUrl(cmsDomain, `/public/${formattedReseller}/${cmsProjectName}/content/search`)
    const withQueryParams = `${endpoint}?page=${search.pageNumber}` +
        (search.pageSize !== undefined ? `&pageSize=${search.pageSize}` : "") +
        (search.reference !== undefined ? `&reference=${encodeURIComponent(search.reference)}` : "") +
        (search.tags !== undefined ? `&tags=${search.tags.map(encodeURIComponent).join(",")}` : "")
    const headers = {"Accept": "application/json"}

    // TODO Sort out error handling
    return axios.get<CmsContent[]>(withQueryParams, {headers: headers})
        .then((response) => response.data)
    // .catch((error) => ({
    //     message: error?.response?.data["message"] ?? "We could not fetch any content at this time. Please refresh the page and try again."
    // }))
}

/**
 * Function will fetch the latest content version from the CMS.
 *
 * @param cmsDomain Base endpoint for the CMS
 * @param cmsProjectName The name of the project that this instance of the sales portal is associated with.
 * @param reseller The name of the Reseller we are trying to get content for.
 */
export const fetchCmsContentVersion = async (
    cmsDomain: string,
    cmsProjectName: string,
    reseller: Reseller
): Promise<RequestError | string> => {
    const formattedReseller = reseller.charAt(0).toUpperCase() + reseller.slice(1)
    const endpoint = joinUrl(cmsDomain, `/public/${formattedReseller}/${cmsProjectName}/content/version`)
    const headers = {"Accept": "text/plain"}

    return axios.get<string>(endpoint, {headers: headers})
        .then((response) => response.data)
}
